import React from "react";
import Accordion from "../../Accordion";

interface SourcesAndToolsProps {
  title?: string;
  features?: any;
}

const SourcesAndTools: React.FC<SourcesAndToolsProps> = props => {
  const { title, features } = props;

  return (
    <div className="text-center md:pt-20 pb-20 px-6 md:pb-24">
      <div className="w-full mx-auto text-center">
        <p className="mb-8 mx-auto max-w-sm font-semibold text-2xl sm:max-w-sm lg:max-w-full">
          {title}
        </p>
      </div>
      <div className="mx-auto lg:max-w-4xl flex flex-col md:flex-row overflow-hidden clear-both justify-center">
        {features?.map((item, index) => {
          return (
            <div className="w-full md:w-[40%] sm:mx-4 lg:mx-8">
              <Accordion
                key={index}
                title={item?.title}
                maxContentHeight={'max-h-[410px]'}
                content={item?.features}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SourcesAndTools;
