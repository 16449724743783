import React from "react";
import GradientTitle from "../../GradientTitle";
import Paragraph from "../../Paragraph";
import Header, { HeaderVariant } from "../../Header";
import Section from "../../../containers/Section";

interface ReportsProps {
  gradientTitle: any;
  gradientTitlePosition: number;
  subTitle: string;
  description: string;
  image: any;
}

const Reports: React.FC<ReportsProps> = props => {
  const { gradientTitle, gradientTitlePosition, subTitle, description, image } =
    props;

  return (
    <div className="px-6 sm:px-4 mt-32 py-20 md:pt-32 rounded-tr-large lg:bg-gradient-to-bl bg-gradient-to-b from-blue-1-gradient lg:via-white to-white">
      <Section className="flex flex-col lg:flex-row ">
        <div className="relative lg:w-1/2 order-2 lg:order-1">
          <div className="flex -mx-2">{image}</div>
        </div>
        <div className="lg:pl-12 lg:text-left lg:w-1/2 order-1 lg:order-2 text-center md:text-left">
          <Header className="text-indigo-1" variant={HeaderVariant.h2}>
            <span className="text-2xl font-semibold">{subTitle}</span>
          </Header>

          <GradientTitle
            title={gradientTitle}
            gradientPosition={gradientTitlePosition}
            variant={HeaderVariant.h3}
            color="purple"
            lineBreak={true}
            className="text-4xl font-semibold mb-6 md:text-5xl md:font-bold md:mb-8 lg:mt-0 max-w-xl"
          />
          <Paragraph className="text-2xl font-semibold">
            {description?.split("/n")?.map(item => {
              return <span className="block mb-4 max-w-xl">{item}</span>;
            })}
          </Paragraph>
        </div>
      </Section>
    </div>
  );
};

export default Reports;
