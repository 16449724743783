import React, { createRef, useEffect } from "react";
import Header, { HeaderVariant } from "../../Header";
import Paragraph from "../../Paragraph";
import GradientTitle from "../../GradientTitle";
import { runAnimation } from "../../../utils/animations";
import BIDataFlow from "../../../animations/BusinessIntelligence/bi-data-flow-desktop.json";
import BIDataFlowMobile from "../../../animations/BusinessIntelligence/bi-data-flow-mobile.json";
import Section from "../../../containers/Section";

interface DataIntegrationsProps {
  subTitle: string;
  gradientTitle: any;
  description: string;
  gradientPosition: number;
}

const DataIntegrations: React.FC<DataIntegrationsProps> = props => {
  const {
    subTitle,
    gradientTitle,
    description,
    gradientPosition,
  } = props;

  const animationContainer = createRef<HTMLDivElement>();
  const mobileAnimationContainer = createRef<HTMLDivElement>();

  useEffect(() => {
    const anim =
      BIDataFlow && runAnimation(animationContainer.current, BIDataFlow);
    const mobileAnim =
      BIDataFlowMobile &&
      runAnimation(mobileAnimationContainer.current, BIDataFlowMobile);
    return () => {
      anim.destroy();
      mobileAnim.destroy();
    };
  }, []);

  return (
    <div className="px-6 sm:px-4 mx-auto">
      <div className="text-center pt-20 md:pt-20">
        <div className="w-full mx-auto lg:max-w-4xl">
          <Header className="text-indigo-1" variant={HeaderVariant.h2}>
            <span className="mb-8 text-lg font-semibold">{subTitle}</span>
          </Header>
          <GradientTitle
            gradientPosition={gradientPosition}
            className="text-4xl font-semibold mb-6 md:text-5xl md:font-bold md:mb-8 mx-auto sm:max-w-lg lg:max-w-full"
            color="purple"
            variant={HeaderVariant.h3}
            title={gradientTitle}
            lineBreak={false}
          />
          <Paragraph className="text-2xl mx-auto font-semibold max-w-2xl">
            {description && description}
          </Paragraph>
        </div>
        <Section>
          <div ref={animationContainer} className="mt-20 hidden lg:block"></div>
          <div
            ref={mobileAnimationContainer}
            className="lg:hidden block mt-12"
          ></div>
        </Section>
      </div>
    </div>
  );
};

export default DataIntegrations;
