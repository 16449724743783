import React from "react";
import Header, { HeaderVariant } from "../../Header";
import Icon, { IconType } from "../../Icon";
import Paragraph from "../../Paragraph";

interface AMCSectionProps {
  title: string;
  description: string;
  cta: any;
  image: any;
}

const AMCSection: React.FC<AMCSectionProps> = props => {
  const { title, description, cta, image } = props;

  return (
    <div
      data-sal="slide-up"
      data-sal-duration="300"
      data-sal-easing="ease"
      className="flex flex-col lg:flex-row my-12 lg:mb-32 lg:mt-0 lg:p-16 px-6 py-12 md:max-w-8xl mx-auto rounded-3xl shadow"
    >
      <div className="lg:w-1/3">
        <div className="flex -mx-2">{image}</div>
      </div>
      <div className="lg:pl-12 lg:text-left lg:w-2/3 self-center text-center md:text-left">
        <Header className="!text-4xl font-semibold" variant={HeaderVariant.h2}>
          <span className="text-2xl md:text-4xl">{title}</span>
        </Header>
        <Paragraph className="text-2xl font-semibold my-10">
          {description}
        </Paragraph>
        <a
          href={cta?.route}
          className="pb-10 text-2xl text-indigo-1 flex items-center group"
        >
          {cta?.text}
          <Icon
            className="ml-2 transition-all group-hover:ml-4"
            iconType={IconType.rightArrow}
          />
        </a>
      </div>
    </div>
  );
};

export default AMCSection;
